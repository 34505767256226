import jwtDecode from 'jwt-decode'
import config from '../config'

const TokenService = {
    saveAuthToken(token) {
        window.sessionStorage.setItem(config.TOKEN_KEY, token)
    },
    getAuthToken() {
        return window.sessionStorage.getItem(config.TOKEN_KEY)
    },
    clearAuthToken() {
        window.sessionStorage.removeItem(config.TOKEN_KEY)
    },
    hasAuthToken() {
        return !!TokenService.getAuthToken()
    },
    makeBasicAuthToken(userName, password) {
        return window.btoa(`${userName}:${password}`)
    },
    parseJwt(jwt) {
        return jwtDecode(jwt)
    },
    readJwtToken() {
        return TokenService.parseJwt(TokenService.getAuthToken())
    },
}

export default TokenService
